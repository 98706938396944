.page {
  min-height: 100vh;
  position: relative;
}

/* bg2 */

.inner {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
}
.bg {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
}
.gradient1,
.gradient2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.gradient1 {
  background-image: linear-gradient(
    179.98deg,
    rgba(255, 255, 255, 0.97) 0.01%,
    rgba(255, 255, 255, 0) 38.1%,
    #ffffff 91.05%,
    #ffffff 99.99%
  );
}
.gradient2 {
  background-image: linear-gradient(
    88.8deg,
    #ffffff 22.24%,
    rgba(255, 255, 255, 0.33) 98.56%
  );
}
.bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*  */
.service {
}
.achievs {
  width: 60%;
  padding: 0px 15px 70px 0;
  /* background: linear-gradient(
    180deg,
    rgba(223, 233, 241, 0.17) 0%,
    rgba(154, 191, 242, 0) 100%
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;

  margin-bottom: 40px; */
}

.text {
  /* max-width: 70%; */
  max-width: 100%;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: var(--blue);
  margin-bottom: 35px;
  text-align: justify;
}
.text p{
  margin-bottom: 18px;
}
.text:last-child {
  margin-bottom: 0;
}
.text span {
  font-weight: 600;
}

.download {
  position: relative;
}

.download::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background-color: var(--blue);
  width: 0%;
  transition: 0.4s ease 0.2s;
}

.download:hover::after {
  width: 80%;
}

@media (max-width: 800px) {
  .achievs {
    width: 100%;
  }
  .text{
    max-width: 100%;
  }
}
