.service {
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 210px;
  margin-top: -100px;
  padding-bottom: 30px;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gradient1,
.gradient2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.gradient1 {
  background-image: linear-gradient(
    180deg,
    rgba(88, 168, 225, 0) 0%,
    rgba(0, 114, 197, 0.45) 36.89%
  );
}
.gradient2 {
  background-image: linear-gradient(
    178.69deg,
    #ffffff 1.65%,
    #fdfdfd 12.29%,
    rgba(224, 238, 249, 0) 67.45%,
    rgba(255, 255, 255, 0) 67.46%,
    #ffffff 102.34%
  );
}
.bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* block */
.block {
  width: 50%;
  padding: 30px 15px;
  background: linear-gradient(
    180deg,
    rgba(223, 233, 241, 0.17) 0%,
    rgba(154, 191, 242, 0) 100%
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  margin-left: -15px;
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.055em;
  color: #ffffff;
  margin-bottom: 20px;
}

@media (max-width: 1100px) {
  .block{
    width: 70%;
  }
}

@media (max-width: 550px) {
  .block{
    width: 100%;
    margin-left: 0;
  }
  .service{
    margin-top: -180px;
  }
}