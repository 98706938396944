* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

button {
  background: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/* конец обнуления */
:root {
  --blue: #003472;
}
body {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  overflow-x: hidden;
}
body.fix {
  overflow: hidden;
}
.container {
  padding: 0 15px;
  max-width: 1230px;
  margin: 0 auto;

  height: inherit;
  position: relative;
  z-index: 5;
}

/* scroll */

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 8px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: rgba(255, 255, 255, 0.2);
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #112349;
}

img[alt="www.000webhost.com"] {
  display: none;
}

/* swiper */
.swiper-slide {
  display: flex;
  justify-content: center;
}
.swiper-pagination {
  left: 48%;
  bottom: -20px;
}
.swiper-pagination-bullet:first-child {
  margin-right: 8px;
}
.swiper-pagination-bullet {
  opacity: 0.6 !important;
  background: rgba(121, 173, 212, 0.8) !important;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: rgba(0, 52, 114, 0.74) !important;
}

.arrow {
  cursor: pointer;
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500000000000;
  right: 20px;
  bottom: 28px;
  box-shadow: 2px 2px 10px #d1cccc;
  opacity: 0;
  transform: scale(0.5);
  visibility: hidden;
  transition: 0.4s ease;
}

.arrow.active {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

/* loader */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500000000;
  background-color: #5093c8;

  transition: 1s ease-in-out;
}

.loader.hide{
  opacity: 0;
  visibility: hidden;
}

.loader_inner {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader_logo {
  margin-bottom: 40px;
  position: relative;
  opacity: 0;

  animation: img 3s linear forwards;
}
.loader_logo_left {
  position: absolute;
  top: 30px;
  left: -140px;
  transform: scale(-1, 1);
  width: 114px;
}
.loader_logo_right{
  position: absolute;
  top: 30px;
  right: -140px;
  width: 114px;
}
.stick {
  background-color: #fff;
  border-radius: 20px;
  height: 5px;
  margin-bottom: 15px;
  opacity: 0;
}

.stick:nth-child(1) {
  animation: stick-1 2.5s ease .5s forwards;
}
.stick:nth-child(2) {
  animation: stick-2 2s ease 1s forwards;
}
.stick:nth-child(3) {
  animation: stick-3 1.5s ease 1.5s forwards;
}

.loader_logo_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5093c8;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  animation: clip-path-anim 3s ease-in-out forwards;
}
.loader_text {
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: 0.11em;
  color: #ffffff;
  opacity: 0;
  animation: text 2s ease forwards 1s;
}
.loader_text p:first-child {
  letter-spacing: 0.17em;
}

@media (max-width: 600px) {
  .loader_text{
    font-size: 25px;
  }
  /*
  .loader_logo_left {
    top: 10px;
    left: -80px;
  }
  .loader_logo_right{
    top: 10px;
    right: -80px;
  } */

  .loader_inner{
    transform: scale(.75);
  }
}
@media (max-width: 450px) {
  .loader_inner{
    transform: scale(.6);
  }
}
@keyframes img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes clip-path-anim {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
}

@keyframes text {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes stick-1 {
  0%{
    width: 0px;
    opacity: 0;
  }
  100%{
    width: 114px;
    opacity: 1;
  }
}
@keyframes stick-2 {
  0%{
    width: 0px;
    opacity: 0;
  }
  100%{
    width: 84px;
    opacity: 1;
  }
}
@keyframes stick-3 {
  0%{
    width: 0px;
    opacity: 0;
  }
  100%{
    width: 42px;
    opacity: 1;
  }
}