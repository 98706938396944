.inner {
  padding-top: 50px;
}
.rupor {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.rupor_img {
  margin-right: 20px;
}
.rupor_text {
  font-weight: 600;
  font-size: 23px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: var(--blue);
}

.vakansia {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.055em;
  color: var(--blue);
  max-width: 532px;

  margin-bottom: 23px;
}
.vakansia span {
  font-weight: 600;
}

/* more */
.button_more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 46px;
  color: #fff;
  background: rgba(127, 176, 215, 0.82);
  border-radius: 20px;

  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: 0.4s ease;
  margin-bottom: 40px;
}
.button_more:hover {
  background: rgb(111, 160, 199);
}
.button_more div {
  margin-right: 7px;
}
.button_img {
  display: flex;
  align-items: center;
  transition: 0.4s ease;
}
.button_img.active {
  transform: rotate(180deg);
}
.more_info {
  color: var(--blue);
  transition: 0.4s ease;
  display: none;
  margin-bottom: 70px;
}
.more_info.active {
  display: block;
}

.more_info {
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.06em;
  color: var(--blue);
}
.more_info span {
  font-weight: 600;
}
.more_info_title {
  margin-bottom: 30px;
}
.date {
  margin-bottom: 20px;
}

.more_info_bottom {
  max-width: 72%;
}

/* anketa */
.anketa_subtitle {
  color: var(--blue);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.055em;
}
.anketa_subtitle span {
  font-weight: 600;
}
.anketa_subtitle p{
  margin-bottom: 10px;
}
.anketa_subtitle p.last{
  margin-top: 25px;
  font-size: 13px;
  margin-bottom: 35px;
}
.achievs {
  margin-top: 20px;
  margin-bottom: 25px;
}
.english {
  font-size: 14px;
  letter-spacing: 0.055em;
  color: var(--blue);
  line-height: 1.5;
}
.download {
  position: relative;
}

.download::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background-color: var(--blue);
  width: 0%;
  transition: 0.4s ease 0.2s;
}

.download:hover::after {
  width: 80%;
}

.top {
  position: relative;
  margin-bottom: 70px;
}
/* bg */
.bg {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
}
.gradient1,
.gradient2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.gradient1 {
  background-image: linear-gradient(
    181.49deg,
    #ffffff 1.21%,
    rgba(255, 255, 255, 0.87) 5.05%,
    rgba(255, 255, 255, 0.13) 33.85%,
    rgba(255, 255, 255, 0.08) 60.81%,
    rgba(255, 255, 255, 0.72) 78.33%,
    #ffffff 98.73%
  );
}
.gradient2 {
  background-image: linear-gradient(
    18deg,
    #ffffff 22.8%,
    rgba(255, 255, 255, 0.647698) 100.66%,
    rgba(255, 255, 255, 0.33) 100.67%
  );
}
.bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 800px) {
  .rupor_text {
    font-size: 20px;
  }

  .more_info_bottom {
    max-width: 100%;
  }
}

@media (max-width: 550px) {
  .button_more {
    padding: 15px 22px;
    font-size: 16px;
  }
}

/* form */
form {
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: transparent;
  margin: 20px 0 60px;
}

form label {
  color: var(--blue);
}
.buttons {
  display: flex;
  margin: 0 -5px;
}
form button,
label[for="file"] {
  padding: 11px 20px;
  background: rgba(127, 176, 215, 0.82);
  border-radius: 20px;
  font-size: 15px;
  letter-spacing: 0.06em;
  color: #ffffff;
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.fileInput {
  position: absolute;
  top: -999999px;
  visibility: hidden;
}
.form_input {
  border-bottom: 2px solid #c5c5c5;
  margin-bottom: 30px;
  padding-bottom: 5px;
  transition: 0.4s ease;
  font-size: 15px;
  color: var(--blue);
}
.form_input:last-of-type{
  margin-bottom: 50px;
}
.form_input.err {
  border-bottom: 2px solid #d80027;
}
.form_input.good {
  border-bottom: 2px solid #11a31f;
}
.form_input:focus {
  border-bottom-color: #6d6363;
}
.form_input::placeholder {
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #c5c5c5;
}
.drag_n_drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border: 1px solid #c5c5c5;
  border-radius: 7px;
  background-color: #fff;
  transition: 0.4s ease;
  margin-bottom: 10px;
}
.drag_n_drop.drag {
  border: 1px dashed #c5c5c5;
  background-color: rgba(34, 112, 228, 0.137);
}
.drag_n_drop.success {
  background: rgba(0, 200, 20, 0.2);
  border: 1px solid #99e9a1;
}
.drag_n_drop__img {
  margin-bottom: 10px;
}
.drag_n_drop__title {
  font-size: 11px;
  letter-spacing: 0.04em;
  color: #c5c5c5;
}
.drag_n_drop.success .drag_n_drop__title {
  color: #000;
}

@media (max-width: 900px) {
  form {
    width: 80%;
  }
}

@media (max-width: 500px) {
  form {
    width: 100%;
  }
  form button,
  label[for="file"] {
    font-size: 13px;
    padding: 10px 16px;
  }
}

.rule{
  max-width: 50%;
}

@media (max-width: 800px) {
  .rule{
    max-width: 100%;
  }
}
