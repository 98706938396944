.header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;

  padding: 25px 0;

  background: rgba(104, 146, 203, 0.17);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.header.footer {
  position: relative;
  background: rgba(80, 147, 200, 0.73);
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}
.logo_img {
  margin-right: 10px;
}
.logo_title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  letter-spacing: -0.045em;
  margin-right: 10px;
}

.logo_subtitle {
  font-style: normal;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.11em;
}
.logo_subtitle p:first-child {
  margin-bottom: 3px;
}

/* nav */
.nav_inner {
  display: flex;
}
.link {
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.045em;
  margin-right: 25px;
  transition: 0.6s ease;
  position: relative;
}
.link::after {
  content: "";
  position: absolute;
  bottom: -4px;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  width: 0;
  background-color: var(--blue);

  transition: 0.4s ease 0.2s;
}
.link:hover::after {
  width: 80%;
  opacity: 1;
}
.link:hover,
.link.active {
  color: var(--blue);
}

.link:last-child {
  margin-right: 0;
}

/* langs */
.languages {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.language:not(.small) {
  margin-right: 10px;
}
.language.small img {
  width: 16px;
  height: 16px;
}
.extra {
  position: absolute;
  left: 2px;
  bottom: -25px;
  opacity: 0;
  transform: scale(0.2);
  transition: 0.4s ease;
}
.extra.active {
  transform: scale(1);
  opacity: 1;
}
.arrow {
  transition: 0.4s ease;
}
.arrow.active {
  transform: rotate(-180deg);
}

/* links extra */
.seamans {
  position: relative;
  margin-right: 25px;
}

.seamans .link {
  margin-right: 0;
}
.links_extra {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 25px;
  left: -15px;
  padding: 15px;
  min-width: 180px;
  border-radius: 10px;

  background: rgba(104, 146, 203, 0.17);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease;
  transform: scale(0.5);
}
.header.header.footer .links_extra {
  top: -120px;
  background: rgba(127, 176, 215, 0.75);
}
.links_extra.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.links_extra .link {
  margin-bottom: 10px;
}
.links_extra .link:last-child {
  margin-bottom: 0px;
}
.links_extra .link:hover::after {
  opacity: 0;
}

.lang_mob {
  display: none;
}

.burger {
  display: none;
  width: 25px;
  height: 15px;
  position: relative;
  cursor: pointer;
  z-index: 6;
}

.burger_line {
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 11px;
  margin-top: 6px;

  transition: 0.4s ease;
}
.burger_line::after,
.burger_line::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 11px;
  left: 0;

  transition: 0.4s ease;
}
.burger_line::before {
  top: 0;
}
.burger_line::after {
  bottom: 0;
}

.burger.active .burger_line {
  background-color: transparent;
}

.burger.active .burger_line::before {
  transform: rotate(315deg);
  top: 6px;
}
.burger.active .burger_line::after {
  transform: rotate(-315deg);
  bottom: 6px;
}
.right {
  display: flex;
  align-items: center;
}
.burger_menu {
  display: none;
  width: 0%;
}
@media (max-width: 1100px) {
  .header {
    background: none;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  .nav {
    display: flex;
    align-items: center;
  }
  .burger {
    display: block;
  }
  .lang_mob {
    display: block;
    margin-right: 15px;
  }
  .nav_inner {
    display: none;
  }

  .burger_menu {
    display: block;

    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;

    transform: translateY(-100%);
    transition: 0.4s ease;
  }
  .burger_menu.active {
    transform: translateY(0);
  }
}
.nav_mob {
  height: 100%;
}
.nav_mob_inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(121, 173, 212, 0.86);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}
.nav_mob_inner a {
  margin-right: 0;
  margin-bottom: 18px;
}
@media (max-width: 500px) {
  .right {
    display: flex;
    flex-direction: column;
  }
  .logo_img {
    margin-right: 5px;
  }
  .logo_title {
    font-size: 17px;
    margin-bottom: 2px;
  }
  .logo_subtitle {
    font-size: 7px;
  }
  .logo_subtitle p:first-child {
    margin-bottom: 1px;
    letter-spacing: 0.205em;
  }
  .burger_menu {
    width: 100%;
  }
}


.designer{
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 13px;
  color: #fff;
}