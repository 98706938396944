.block {
  height: 100vh;
  width: 100%;
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gradient1,
.gradient2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.gradient1 {
  background-image: linear-gradient(
    180deg,
    rgba(88, 168, 225, 0) 0%,
    rgba(0, 114, 197, 0.45) 36.89%
  );
}
.gradient2 {
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.13) 10.33%
  );
  transform: rotate(-180deg);
}
.bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
}
.content_inner {
}
.title {
  font-weight: 500;
  font-size: 34px;
  letter-spacing: 0.055em;
  color: #ffffff;
  margin-bottom: 28px;
  opacity: 0;
  animation: title 1.5s ease forwards;
}

.subtitle {
  font-size: 19px;
  font-weight: 300;
  max-width: 500px;
  line-height: 1.5;
  letter-spacing: 0.045em;
  color: #ffffff;
  margin-bottom: 30px;
  opacity: 0;
  animation: title 1.5s ease forwards 0.5s;
}
.subtitle p:first-child {
  margin-bottom: 10px;
}
.subtitle p:nth-child(2) {
  max-width: 445px;
}
.socials {
  display: flex;
  align-items: center;
  opacity: 0;
  animation: title 1.5s ease forwards 1s;
}
.social_link {
  cursor: pointer;
  margin-right: 20px;
  transition: 0.4s ease;
}
.social_link:hover {
  transform: scale(1.2);
}
.social_link:last-child {
  margin-right: 0;
}
.subtitle.mob{
  display: none;
}
@keyframes title {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}



@media (max-width: 550px) {
  .block{
    text-align: center;
    overflow: hidden;
  }
  .socials{
    justify-content: center;
  }

  .bg {
  }
  .bg img{
    width: 180%;
  }
  .title{
    font-size: 25px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  .subtitle{
    font-size: 14px;
    font-weight: 400;
  }
  .content_inner{
    margin-top: -20px;
  }
  .subtitle{
    display: none;
  }
  .subtitle.mob{
    display: block;
  }
}